<template>
    <div class="mt-5">
        <styled-card hide-toggle="false">
            <template #heading>
                Enable Two-Factor Authentication
            </template>

            <div class="container">
                <div v-if="!twoFaConfirmed">
                    <div class="">
                        <v-card-title class="subheading pb-0">
                            Configure Two-Factor Authentication
                        </v-card-title>

                        <v-card-text class="pt-0">
                            Once configured, you'll be required to enter a code created by the
                            <a
                                href="https://support.google.com/accounts/answer/1066447"
                                target="_blank">Google Authenticator</a>,
                            <a
                                href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                                target="_blank">Microsoft Authenticator</a>,
                            <a
                                href="https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app#download"
                                target="_blank">Duo Mobile</a>,
                            <a
                                href="https://authy.com/download/"
                                target="_blank">Authy</a>
                            or
                            <a
                                href="https://lastpass.com/misc_download2.php"
                                target="_blank">LastPass</a> app in order to sign into your (agency)
                            account. Learn more about two-factor authentication at
                            <a
                                href="https://support.buyerbridge.com/knowledge/how-to-set-up-2-factor-authentication"
                                target="_blank">help page</a>.
                        </v-card-text>
                    </div>

                    <div>
                        <div class="row py-5">
                            <div class="visual">
                                <img src="/img/two-fa-authenticator.png">
                            </div>
                            <div class="content">
                                <h3>Step 1</h3>
                                <h4>Get the App</h4>
                                <p>
                                    Download and install the
                                    <a
                                        href="https://support.google.com/accounts/answer/1066447"
                                        target="_blank">Google Authenticator</a>,
                                    <a
                                        href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                                        target="_blank">Microsoft Authenticator</a>,
                                    <a
                                        href="https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app#download"
                                        target="_blank">Duo Mobile</a>,
                                    <a
                                        href="https://authy.com/download/"
                                        target="_blank">Authy</a>
                                    or
                                    <a
                                        href="https://lastpass.com/misc_download2.php"
                                        target="_blank">LastPass</a>
                                    app for your phone or tablet.
                                </p>
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="visual">
                                <div v-html="twoFaQrCode" />
                            </div>
                            <div class="content">
                                <h3>Step 2</h3>
                                <h4>Scan this Barcode</h4>
                                <p>
                                    Open the authentication app and:
                                    <ul>
                                        <li>Tap the "+" icon in the top-right of the app</li>
                                        <li>Scan the image to the left, using your phone's camera</li>
                                        <a
                                            href="https://support.google.com/accounts/answer/185834"
                                            target="_blank">(Can't scan this barcode?)</a>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="visual">
                                <v-form
                                    id="two-factor-authentication"
                                    @submit.prevent="submit">
                                    <v-text-field
                                        v-model="code"
                                        label="code"
                                        required
                                        class="styled-field"
                                        @keydown="clearError" />
                                    <small
                                        v-if="error"
                                        class="ml-5 red--text">
                                        {{ error }}
                                    </small>
                                    <div class="form-button mt-4">
                                        <v-btn
                                            flat
                                            large
                                            class="blue-btn"
                                            color="white"
                                            type="submit"
                                            form="two-factor-authentication"
                                            :disabled="!code">
                                            VERIFY CODE
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                            <div class="content">
                                <h3>Step 3</h3>
                                <h4>Enter Verification Code</h4>
                                <p>Once the barcode above is scanned, enter the 6-digit verification code generated by the app.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <v-container
                        text-center
                        fluid
                        grid-list-md>
                        <p>
                            Your Two Factor Authentication is set up.
                        </p>
                    </v-container>
                </div>
            </div>
        </styled-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard.vue';

export default {
    components: { StyledCard },
    title: 'Two Factor Authentication',
    data() {
        return {
            error: '',
            code: '',
            twoFaQrCode: '',
            twoFaConfirmed: false,
            subBaseUrl: '/user',
        };
    },
    computed: {
        ...mapGetters([
            'currentUser',
        ]),
        ...mapState({
            isLoggedIn: (state) => state.user.isLoggedIn,
            tempLoginDetails: (state) => state.user.tempLoginDetails,
        }),
    },
    async mounted() {
        this.twoFaConfirmed = this.isLoggedIn && !!this.currentUser.two_factor_confirmed_at;
        await this.getQrCode();
    },
    activated() {
        this.twoFaConfirmed = !!this.currentUser.two_factor_confirmed_at;
        this.error = '';
        this.code = '';
        this.getQrCode();
    },
    methods: {
        getQrCode() {
            const { email, password } = this.tempLoginDetails;

            if (email && password) {
                this.subBaseUrl = '/user/login';
            }

            this.$http.get(`${this.subBaseUrl}/two-factor-qr-code`, { email, password })
                .then(response => {
                    this.twoFaQrCode = response.data.svg;
                })
                .catch(response => {
                    if (response.data.errors) {
                        this.error = response.data.errors;
                    } else {
                        this.error = 'Error! Please try again.';
                    }
                });
        },
        submit() {
            const { email, password } = this.tempLoginDetails;

            if (email && password) {
                this.subBaseUrl = '/user/login';
            }

            this.$http.post(`${this.subBaseUrl}/confirmed-two-factor-authentication`, {
                'code': this.code,
                'username': email,
                password
            })
                .then(async response => {
                    this.twoFaConfirmed = true;
                    const data = response.data;
                    if (!this.isLoggedIn) {
                        await this.$store.dispatch('completeLogin', { data, init: true });
                    } else {
                        await this.$router.push({ name: 'user-settings' });
                    }
                })
                .catch(response => {
                    const responseErrors = response.response.data.error.messages[0];
                    if (responseErrors) {
                        this.error = responseErrors;
                    } else {
                        this.error = 'Error! Please try again.';
                    }
                });
        },
        clearError() {
            this.error = '';
        }
    }
};
</script>

<style>
.subheading {
    color: #00A4EB;
    font-size: 1.25rem;
    font-weight: 500;
}

.row {
    display: flex;
    align-items: center;
}

.row img {
    max-width: 250px;
}

.row:not(:last-child) {
    border-bottom: solid lightgray 1px;
}

.visual {
    flex: 1;
    text-align: center;
}

.content {
    flex: 2;
    padding-left: 2rem;
}

.content h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

.content h4 {
    margin-bottom: 0.3rem;
    font-size: 1.4rem;
    color: black;
}

.form-button {
    text-align: center;
}

.styled-field,
.form-button {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
}

.styled-field {
    width: 100%;
}

</style>
